import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const PostFooterAuthorBio = ({ imageFixed }) => (
  <div
    className="font-sans text-base p-4 flex gap-4 border-t border-b-2 border-gray-50"
    itemProp="author"
    itemScope=""
    itemType="http://schema.org/Person"
  >
    <meta itemProp="url" content="https://serverlessfirst.com" />
    <Img
      className="rounded-full border-2 border-blue-100 flex-none align-top"
      fixed={imageFixed}
    />
    <div className="inline-block align-middle flex-1">
      <h4 itemProp="name" className="m-0">
        Paul Swail
      </h4>
      <p>
        <span className="italic">
          Indie Cloud Consultant helping small teams learn and build with
          serverless.
        </span>
        <br />
        Learn more how I can help you <Link to="/services/">here</Link>.
      </p>
    </div>
  </div>
);

export default PostFooterAuthorBio;
