import React from 'react';
import { Link } from 'gatsby';
import siteConfig from '../../data/SiteConfig';

const Sidebar = () => (
  <div className="flex flex-col gap-8 max-w-sm">
    {/* FREE INTRO CALL */}
    {/* <div className="bg-gray-100 px-6 py-4 font-sans text-base xl:text-lg border-t-4 border-b-4 border-gray-300 rounded-lg">
      <h3 className="mt-0 text-center uppercase tracking-wider text-gray-700">
        Free Intro Call
      </h3>
      <div className="max-w-md mx-auto">
        <p className="mb-2">
          Book a free 30-minute introduction call with me to see how we could
          work together.
        </p>
        <p className="text-center my-0 pt-4">
          <Link
            to={siteConfig.introCall.bookingUrl}
            className="btn inline-block font-sans py-3 px-3 leading-5 rounded-md bg-primary-500 hover:bg-primary-600 text-white active:bg-primary-700 mb-4"
          >
            Select a time for our call
          </Link>
        </p>
      </div>
    </div> */}

    {/* LAUNCHPAD */}
    {/* <div className="bg-gray-100 px-6 py-4 font-sans text-base xl:text-lg border-t-4 border-b-4 border-gray-300 rounded-lg">
      <h3 className="mt-0 text-center uppercase">🛫 Serverless Launchpad</h3>
      <div className="max-w-md mx-auto">
        <p className="mb-2 font-bold italic">
          Ready to start building your new AWS serverless project but need help
          with getting everything setup?
        </p>
        <p className="mb-0">
          The Serverless Launchpad is a done-for-you DevOps service installed in
          under a week. You get a leading-practice multi-account AWS
          environment, a scaffolded codebase and architecture including the
          common AWS serverless services, isolated cloud environments for
          individual developers, automated delivery pipelines right through to
          production and much more. Everything is IaC, extensively documented
          and handed over to your developers.
        </p>

        <p className="text-center my-0 pt-4">
          <Link
            to="/services/launchpad/"
            className="btn inline-block font-sans py-1 px-2 text-sm leading-5 font-medium rounded-md bg-gray-200 hover:bg-gray-300 text-primary-600 active:bg-gray-300"
          >
            Learn more &gt;&gt;
          </Link>
        </p>
      </div>
    </div> */}

    {/* ARCH REVIEW */}
    <div className="bg-gray-100 px-6 py-4 font-sans text-base xl:text-lg border-t-4 border-b-4 border-gray-300 rounded-lg">
      <h3 className="mt-0 text-center uppercase tracking-wider text-gray-700">
        🩺 <br />
        Architecture &amp; Process Review
      </h3>
      <div className="max-w-md mx-auto">
        <p className="mb-2 font-bold italic">
          Built a serverless app on AWS, but struggling with performance,
          maintainability, scalability or DevOps practices?
        </p>
        <p className="mb-0">
          I can help by reviewing your codebase, architecture and delivery
          processes to identify risk areas and their causes. I will then
          recommend solutions and help you with their implementation.
        </p>

        <p className="text-center my-0 pt-4">
          <Link
            to="/services/review/"
            className="btn inline-block font-sans py-2 px-4 text-base leading-5 font-medium rounded-md bg-gray-300 hover:bg-gray-400 text-primary-600 active:bg-gray-400"
          >
            Learn more &gt;&gt;
          </Link>
        </p>
      </div>
    </div>

    {/* TESTING AUDIT */}
    <div className="bg-gray-100 px-6 py-4 font-sans text-base xl:text-lg border-t-4 border-b-4 border-gray-300 rounded-lg">
      <h3 className="mt-0 text-center uppercase tracking-wider text-gray-700">
        🪲 Testing Audit
      </h3>
      <div className="max-w-md mx-auto">
        <p className="mb-2 font-bold italic">
          Are bugs in production slowing you down and killing confidence in your
          product?
        </p>
        <p className="mb-0">
          Get a tailored plan of action for overhauling your AWS serverless
          app’s tests and empower your team to ship faster with confidence.
        </p>

        <p className="text-center my-0 pt-4">
          <Link
            to="/services/testing-audit/"
            className="btn inline-block font-sans py-2 px-4 text-base leading-5 font-medium rounded-md bg-gray-300 hover:bg-gray-400 text-primary-600 active:bg-gray-400"
          >
            Learn more &gt;&gt;
          </Link>
        </p>
      </div>
    </div>
  </div>
);

export default Sidebar;
