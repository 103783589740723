/* eslint-disable react/no-unknown-property */
import React from 'react';
import moment from 'moment';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from './layout';
import MetaTags from '../components/MetaTags';
import { DailyNewsletterForm } from '../components/ConvertkitInlineForm';
import config from '../../data/SiteConfig';
import BlogPostSidebar from '../components/BlogPostSidebar';
import PostFooterAuthorBio from '../components/PostFooterAuthorBio';
import './b16-tomorrow-dark.css';

const DEFAULT_OG_IMAGE = '/img/daily-emails-banner_2022-02.png';

export default function EmailPostTemplate({ data, pageContext }) {
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const { frontmatter } = postNode;
  const publishDate = postNode.fields.date;
  const cover = frontmatter.image_url;
  const showCover = cover && frontmatter.show_top_image;
  const coverImageFluid = cover?.childImageSharp.fluid || null;
  const twitterCardType = frontmatter.twitter_card || 'summary_large_image';
  const { image_credit } = frontmatter;
  if (!frontmatter.id) {
    frontmatter.id = slug;
  }
  if (!frontmatter.category_id) {
    frontmatter.category_id = config.postDefaultCategoryID;
  }

  const description = `${frontmatter.description} |\nA daily email newsletter on building software with serverless`;
  const socialDescription = frontmatter.social_description || description;

  return (
    <Layout>
      <MetaTags
        path={slug}
        title={frontmatter.subject}
        description={description}
        socialDescription={socialDescription}
        appendSiteTitleToPageTitle={false}
        image={coverImageFluid ? coverImageFluid.src : DEFAULT_OG_IMAGE}
        twitterCardType={twitterCardType}
        isBlogPost="true"
        publishedDate={publishDate}
      />
      <div className="">
        <div className="grid grid-cols-2 lg:grid-cols-3 px-4 md:px-8 lg:gap-16 max-w-7xl lg:mx-auto">
          <article className="blog-post max-w-3xl mx-auto col-span-2">
            <header className="mb-4">
              {showCover && (
                <div className="-mx-4 md:-mx-8">
                  <Img
                    fluid={coverImageFluid}
                    className="w-full"
                    alt={frontmatter.coverAlt || frontmatter.title}
                  />
                  {image_credit && (
                    <div className="pl-2 font-sans text-gray-500 text-sm text-right px-2 py-1">
                      {image_credit}
                    </div>
                  )}
                </div>
              )}
              <h1 className="mt-5 mb-2 font-extrabold">
                {frontmatter.subject}
              </h1>
              <div className="font-sans">
                <span
                  itemprop="author"
                  itemscope=""
                  itemtype="http://schema.org/Person"
                >
                  <span itemprop="name">By Paul Swail</span>
                </span>
              </div>
              <div className="font-sans text-base text-gray-700 my-2 grid md:grid-cols-3">
                <time
                  itemprop="datePublished"
                  datetime={moment(publishDate, config.dateFromFormat).format(
                    'YYYY-MM-DD'
                  )}
                >
                  {moment(publishDate, config.dateFromFormat).format(
                    'MMMM D, YYYY'
                  )}
                </time>
              </div>
            </header>

            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            <div className="my-4">
              <PostFooterAuthorBio
                imageFixed={data.paulImage.childImageSharp.fixed}
              />
            </div>

            <div className="-mx-4 my-8 bg-gray-100 px-4 md:px-8 py-2 rounded-xl">
              <h2>Join daily email list</h2>
              <p>
                I publish short emails like this on building software with
                serverless on a daily-ish basis. They’re casual, easy to digest,
                and sometimes thought-provoking. If daily is too much, you can
                also join my less frequent{' '}
                <Link to="/newsletter/">newsletter</Link> to get updates on new
                longer-form articles.
              </p>
              <DailyNewsletterForm />
              <p>
                <Link to="/emails/">View Emails Archive</Link>
              </p>
            </div>
          </article>
          <div className="col-span-3 lg:col-span-1 mx-auto">
            <BlogPostSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query EmailPageQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        subject
        description
        social_description
        date
        image_url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_credit
        show_top_image
      }
      fields {
        slug
        date
      }
    }
    paulImage: file(
      relativePath: { eq: "paulswail_2021-09_sideprofile.jpeg" }
    ) {
      childImageSharp {
        fixed(width: 60, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
